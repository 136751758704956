<template>
  <v-container fluid>
    <crm-table-pageable-new
      :headers="headers"
      :data="events"
      :config="config"
      :loaded-options="options"
      :total-elements="totalElements"
      :total-pages="totalPages"
      :available-filters.sync="availableFilters"
      :filters-dictionaries="filtersDictionaries"
      @onChangeData="onChangeData"
    ></crm-table-pageable-new>
  </v-container>
</template>
<script>
import crmTablePageableNew from '@/components/crm-table-pageable-new/index.vue';

export default {
  name: 'promoCodes',
  components: {
    'crm-table-pageable-new': crmTablePageableNew,
  },
  data() {
    return {
      headers: [
        {
          value: 'id',
          sortable: false,
          text: 'Номер',
          type: 'defaultItem',
        },
        {
          value: 'createdDate',
          sortable: true,
          text: 'Дата создания',
          type: 'defaultItem',
        },
        {
          value: 'event.promoCode',
          sortable: false,
          text: 'Промокод',
          type: 'defaultItem',
        },
        {
          value: 'startDate',
          sortable: false,
          text: 'Дата начала',
          type: 'defaultItem',
        },
        {
          value: 'finishDate',
          sortable: false,
          text: 'Дата окончания',
          type: 'defaultItem',
        },
        {
          value: 'user.name',
          sortable: false,
          text: 'Пользователь',
          type: 'defaultItem',
        },
        {
          value: 'user.username',
          sortable: false,
          text: 'Логин пользователя',
          type: 'defaultItem',
        },
        {
          value: 'usedDate',
          sortable: false,
          text: 'Дата использования',
          type: 'defaultItem',
        },
        {
          value: 'status',
          sortable: false,
          text: 'Статус',
          type: 'enums',
          enums: 'promoStatusEnums',
        },
      ],
      config: {
        search: true,
        searchPlaceholder: 'Поиск по ...',
        pageable: true,
      },
      availableFilters: [
        {
          id: 'usedDateRange',
          name: 'Дата',
          type: 'dateRange',
          menu: false,
          active: false,
        },
        {
          id: 'statusList', name: 'Статус промокода', type: 'select', active: false,
        },
      ],
      filtersDictionaries: {
        statusList: [
          { value: 'ACTIVATED', label: 'Активный' },
          { value: 'COMPLETED', label: 'Использован' },
          { value: 'COMPLETED', label: 'Отменненый' },
        ],
      },
      totalElements: 0,
      totalPages: 0,
      url: '/api/v1/crm/promo/search',
      options: {
        page: 1,
        size: 10,
      },
      events: [],
      dialog: false,
      eventId: null,
    };
  },
  mounted() {
    this.onChangeData(this.$route.query);
  },
  methods: {
    onChangeData(options) {
      let resultOptions = { ...options };
      if (!Object.values(options).length) {
        resultOptions = { ...this.options };
      }
      this.$router
        .replace({
          path: this.$route.path,
          params: {},
          query: resultOptions,
        })
        .catch(() => {});
      this.options = { ...resultOptions };
      this.getInputs();
    },
    getInputs() {
      const params = this.$route.query;
      const formattedUrl = this.url;

      this.$loading(true);
      this.$api
        .get(formattedUrl, {
          params: {
            page: params.page - 1,
            size: params.size,
            sort: params.sort,
            promocode: params.search,
            user: params.search,
            from: params.usedDateRange && params.usedDateRange.split(',')[0],
            to: params.usedDateRange && params.usedDateRange.split(',')[1],
            status: params.statusList,
          },
        })
        .then((response) => {
          this.totalElements = response.totalElements;
          this.totalPages = response.totalPages;
          this.events = [...response.content].map((el) => ({
            ...el,
            createdDate: this.getFormattedDate(el.event.createdDate),
            startDate: this.getFormattedDate(el.event.startDate),
            finishDate: this.getFormattedDate(el.event.finishDate),
            usedDate: this.getFormattedDate(el.usedDate),
          }));
        })
        .finally(() => {
          this.$loading(false);
        });
    },
    getFormattedDate(date) {
      if (!this.$moment(date).isValid()) return 'Нет данных';
      return this.$moment(date).format('DD.MM.YYYY');
    },
  },
};
</script>
<style lang="scss" scoped></style>
